export const CURRENT_CONFIG = {

  // license
  appId: '148713', // You need to go to the development website to apply.
  appKey: '86009142e023ca93bc51d73279a2c9d', // You need to go to the development website to apply.
  appLicense: 'NMrLizBSTPw5B7V0RLQgl1DiLvCL4Rto6iO89TUG0AjZseeyMoYLPlJlaoZxfIw3mQM+hBSeD/UU8XtkffcrtSjSOtmFGlDsdB+r4j30e9IQ+CjFfoIHQTseFvKfzFiuJDZIabEcSN1FS5NucscMvgZPBuhpH9CfKHY4rxXLy0s=', // You need to go to the development website to apply.

  // http
  baseURL: 'https://dxqb.nenggongshe.com/dj/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  websocketURL: 'wss://dxqb.nenggongshe.com/dj/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  rtmpURL: 'Please enter the rtmp access address.', // Example: 'rtmp://192.168.1.1/live/'
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: 'Please enter the server ip.',
  gbServerPort: 'Please enter the server port.',
  gbServerId: 'Please enter the server id.',
  gbAgentId: 'Please enter the agent id',
  gbPassword: 'Please enter the agent password',
  gbAgentPort: 'Please enter the local port.',
  gbAgentChannel: 'Please enter the channel.',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'aab955beb250400d92e7aa7d033545b0',
  agoraToken: '007eJxTYHj9dlHpGe1/mdMe+Zg+WXsmKSV9t8fla7zvS269v1VxtotDgSExMcnS1DQpNcnI1MDEwCDF0ijVPDHRPMXA2NjUxDTJQHxdf1pDICODfns4MyMDBIL4LAwlqcUlDAwAfm8ihg==',
  agoraChannel: 'test',

  // map
  // You can apply on the AMap website.
  amapKey: 'Please enter the amap key.',

}
